import {ICookieMessageConfig} from "../../Interfaces/Configuration/ICookieMessageConfig";

export class Configuration implements ICookieMessageConfig {
    Breakpoint: number = 1024;
    Button: boolean = false;
    ButtonClass: string = '';
    ButtonText: string = 'Accept and Close';
    Duration: number = 365;
    Message: string = 'This is a placeholder message for cookie notification. Please replace with your own copy.';
    Position: string = 'Bottom';
    Title: string = 'Cookie Notice';
}
